import { Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import { declareResult, setRank } from "../../../services/apiFunctions";
import { messageConfiguration } from "../../../utils";

const ResultModel = ({ OpenResult, setOpenResult, Fill }) => {
  const [data, setData] = useState([]);
  const [message, setMessage] = useState("");

  function getResult(id = Fill._id) {
    declareResult(`/${id}?role=admin`)
      .then((response) => {
        setMessage(response.data.message);
        setData(
          response.data.data.map((i) => {
            return { name: i.name??"", point: i.point??"", rank: "" };
          })
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    // winnerList();
    getResult();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleChange = (val, index) => {
    let endeddata = [...data];
    endeddata[index] = { ...endeddata[index], rank: val };
    setData(endeddata);
  };
  const handleOk = () => {
    const payload = {
      rank_list: data,
      pool_id: Fill._id,
      role: "admin",
    };
    setRank(payload)
      .then((response) => {
        
        message.open(messageConfiguration("success", "Result Declared", 3));
      })
      .catch((error) => console.log(error));
    setOpenResult(false);
  };
  const handleCancel = () => {
    setOpenResult(false);
  };
  return (
    <>
      <Modal
        footer={null}
        open={OpenResult}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div>
          <div >
            <h1 className="shadow-sm mt-4 shadow-slate-300 bg-primary  text-center text-2xl ">
              {message}
            </h1>
            <p className="mt-2 font-medium text-center text-lg">
              Total Users : {data.length}
            </p>
            <div className="h-[250px] p-2  overflow-y-scroll">
              {data?.map((i, index) => {
                return (
                  <>
                    <div className="flex justify-start items-center gap-4 mt-6">
                      <p className="font-semibold">{index + 1}.</p>
                      <p className="w-1/2 capitalize">{i.name}</p>
                      <p>{i.point}</p>
                      <Select
                        placeholder="---Select rank---"
                        onChange={(val) => handleChange(val, index)}
                        className="w-full"
                        options={[
                          { label: 1, value: "1" },
                          { label: 2, value: "2" },
                          { label: 3, value: "3" },
                        ]}
                      />
                    </div>
                  </>
                );
              })}
            </div>
            {/* {data.hasOwnProperty("winnerDetail") &&
              Object.keys(data.winnerDetail).length !== 0 &&
              Object.entries(data.winnerDetail)
                .map(([k, v]) => {
                  return {
                    name: k,
                    point: v,
                  };
                })
                .sort((a, b) => a.point - b.point)
                .map((i, index) => (
                  <p key={index}>
                    {i.name}: {i.point}
                  </p>
                ))} */}
          </div>

          <div className="flex justify-end items-center gap-4 cursor-pointer mt-4">
            <p
              className="bg-blue-600 hover:bg-blue-800 text-white text-xs p-2 rounded-md"
              onClick={handleCancel}
            >
              Cancel
            </p>
            <p
              className="bg-primary hover:bg-primaryhover text-white text-xs p-2 rounded-md "
              onClick={() => {
                data.length > 0 ? handleOk() : handleCancel();
              }}
            >
              {data.length > 0 ? "Set Winner" : "Ok"}
            </p>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ResultModel;
