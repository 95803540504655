import React, { useEffect, useState } from "react";
import { Spin, Pagination, Button } from "antd";
import { ExtractDate, ExtractTime } from "../../../utils";
import { winnerList } from "../../../services/apiFunctions";
import WinnerModal from "./WinnerModel";
function QuizWinners() {
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState([]);
  const [totalPage, setTotalPages] = useState();
  const[currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [fillData, setFillData] = useState([]);
  function PoolResultData() {
    winnerList()
      .then((response) => {
        setData(response.data.data);
        setCurrentPage(response.data.currentPage);
        setTotalPages(response.data.totalPage);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }
  useEffect(() => {
    PoolResultData();
  }, []);
  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
  };
  return (
    <div>
      <>
        {isLoading ? (
          <div className="text-center mt-52">
            <Spin size="md" />
          </div>
        ) : (
          <>
            <div className="bg-primary mt-2 text-white font-bold p-2 text-lg">
              Quiz Winners
            </div>

            {data.length !== 0 ? (
              data.map((i, index) => {
                return (
                  <>
                    <div
                      key={index}
                      className="bg-white shadow-md shadow-gray-400 p-4 m-4 rounded-md"
                    >
                      <div className="grid grid-cols-1 gap-14 md:grid-cols-4">
                        <div>
                          <div className="flex justify-start capitalize items-center">
                            <p className="font-semibold">{index + 1}</p>.{" "}
                            <p className="text-base">{i.subject}</p>
                          </div>
                        </div>
                        <p>
                          <span className="font-semibold">Start Time</span>:{" "}
                          {ExtractDate(i.pool_start_time)}{" "}
                          {ExtractTime(i.pool_start_time)}
                        </p>
                        <p>
                          <span className="font-semibold">End Time</span>:{" "}
                          {ExtractDate(i.pool_end_time)}{" "}
                          {ExtractTime(i.pool_end_time)}
                        </p>

                        <Button
                          style={{ color: "black" }}
                          className="bg-primary cursor-pointer flex justify-center border-none items-center text-xs font-semibold  h-8 px-4 text-black   rounded-md "
                          onClick={() => {
                            setFillData(i);
                            setOpenModal(true);
                          }}
                        >
                          Winners {" "}🏆
                        </Button>
                      </div>
                    </div>
                  </>
                );
              })
            ) : (
              <p className="flex justify-center items-center h-full font-semibold text-white">
                No Result Declared
              </p>
            )}
            {openModal && (
              <WinnerModal
                isOpen={openModal}
                setIsOpen={setOpenModal}
                Fill={fillData}
              />
            )}
            {data.length !== 0 && (
              <Pagination
              className="bg-white w-full rounded-md"
                  onChange={handlePageChange}
                  current={currentPage}
                defaultCurrent={1}
                  total={10 * totalPage}
              />
            )}
          </>
        )}
      </>
    </div>
  );
}
export default QuizWinners;
