import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  Answers: {},
}

export const answerSlice = createSlice({
  name: 'answer',
  initialState,
  reducers: {
    setanswer: (state,action) => {
      state.Answers[action.payload.id] =action.payload.answer
    },
    clearAnswer: (state,action) => {
      state.Answers ={}
    },
    deleteAlreadyExist:(state,action)=>{
        const filter=state.Answers.filter((i)=>i.question_id!==action.payload)
        state.Answers =filter
    }

  },
})

// Action creators are generated for each case reducer function
export const { setanswer,deleteAlreadyExist ,clearAnswer} = answerSlice.actions

export default answerSlice.reducer