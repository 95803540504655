import { Button, Input, Modal, Radio, message } from "antd";
import React, { useEffect, useState } from "react";
import { MdCancel } from "react-icons/md";
import { useLocation } from "react-router";
import { messageConfiguration } from "../../../utils";
import { addQuestion, editQuestion } from "../../../services/apiFunctions";
import { useDispatch } from "react-redux";
import {  setanswer } from "../../../Redux/ReduxSlice";
const QuestionModal = ({
  isOpen,
  setIsOpen,
  Fill,
  from,

}) => {
  const location = useLocation();
  const { pool_id } = location.state;
  const [options, setOptions] = useState([]);
  const [correctAnswer, setCorrectAnswer] = useState("");
  const [question, setQuestion] = useState("");
  const [questionPoint, setQuestionPoint] = useState();
  // const getAnswerObj = useSelector((state) => state.answer.Answers);
  const dispatch = useDispatch();
  const handleOk = () => {
    setIsOpen(false);
  };
  useEffect(() => {
    if ((Fill && from === "edit") || from === "setanswer") {
      setQuestion(Fill.question);
      setQuestionPoint(Fill.points);
      setOptions(
        Fill.options.map((i, index) => {
          return {
            // name: i[index + 1],
            option_id: [index + 1],
            option_value: i.option_value,
            // points: i.points,
          };
        })
      );
      // setCorrectAnswer(getAnswerObj[Object.keys(getAnswerObj).filter((key,index)=>key===Fill._id)]??1)
      setCorrectAnswer(Number(Fill.correct_answer));
      
    }
  }, [Fill, from]);
  const handleCancel = () => {
    setIsOpen(false);
  };
  const handleAdd = () => {
    let payload = {
      pool_id: pool_id,
      question: question,
      points: questionPoint,

      option_list: options.map((i, index) => {
        return {
          option_id: index + 1,
          option_value: i.option_value,
        };
      }),
    };
    if (from === "edit") {
      payload["question_id"] = Fill._id;
    }
    if (from === "add") {
      addQuestion(payload)
        .then((response) => {
          if (response.data.status) {
            message.success(response.data.message);
            setIsOpen(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (from === "edit") {
      editQuestion(payload)
        .then((response) => {
          if (response.data.status) {
            message.success(response.data.message);
            setIsOpen(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      dispatch(setanswer({ answer: correctAnswer[0], id: Fill._id }));
      setIsOpen(false);
    }
  };
  const handleAddOption = () => {
    setOptions([
      ...options,
      {
        option_id: "",
        option_value: "",
        // points: 0,
      },
    ]);
  };
  const handleChange = (index, e) => {
    const { name, value } = e.target;
    let data = [...options];
    data[index] = { ...data[index], [name]: value };
    setOptions(data);
  };
  const handleRemove = (index) => {
    let data = [...options];
    data.splice(index, 1);
    setOptions(data);
  };
  const checkDisabled = () => {
    if (!question) {
      message.open(
        messageConfiguration("error", "Fill the question field!", 3)
      );
    } else if (questionPoint === 0) {
      message.open(
        messageConfiguration("error", "Add point for a question.", 3)
      );
    } else if (options.length === 0) {
      message.open(messageConfiguration("error", "Atleat add one option", 3));
    } else if (
      options.filter((i) => !i.option_value && !i.points).length !== 0
    ) {
      message.open(messageConfiguration("error", "Fill Options Field !", 3));
    } else {
      handleAdd();
    }
  };
  const handleCorrect = (index) => {
    setCorrectAnswer(index);
  };
  return (
    <>
      <Modal
        footer={null}
        open={isOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        title={
          from === "add"
            ? "Add Question"
            : from === "setanswer"
            ? "Set Answer"
            : "Edit Question"
        }
      >
        <div>
          <div className="flex justify-around items-center gap-4">
            <Input
              disabled={from === "setanswer"}
              type="text"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              className=" text-sm  mt-1 w-full border-gray-200 border-2 focus:border-primary  h-9 px-2 "
              placeholder="Enter Question"
            />
            <Input
              disabled={from === "setanswer"}
              value={questionPoint}
              name="points"
              onChange={(e) => setQuestionPoint(e.target.value)}
              type="Number"
              min={0}
              max={10}
              placeholder="Set Points"
              className=" text-sm  mt-1 w-1/4 border-gray-200 border-2 hover:border-primary  h-9 px-2"
            />
          </div>
        
          <div className="flex justify-end items-center mt-4 ">
            <Button
              disabled={from === "setanswer"}
              onClick={handleAddOption}
              className="bg-primary rounded-md text-xs text-white p-2 cursor-pointer "
            >
              Add Option
            </Button>
          </div>
          <div className="w-full  bg-loginbg p-4 mt-4 h-[250px] overflow-y-scroll ">
          {options.map((i, index) => {
              return (
                <div
                  key={index}
                  className="flex gap-4 justify-between mt-4 items-center "
                >
                  {from === "setanswer" ? (
                    <div className="radio-group">
                      <Radio.Group
                        className="w-full"
                        value={correctAnswer}
                        
                        onChange={() => handleCorrect(i.option_id)}
                      >
                        <Radio value={i.option_id}>
                          {" "}
                          <Input
                            disabled={from === "setanswer"}
                            value={i.option_value}
                            name="option_value"
                            onChange={(e) => handleChange(index, e)}
                            type="text"
                            placeholder="Option"
                            className="outline-none text-sm border mt-1 w-full border-gray-200 hover:border-primary  h-9 "
                          />
                        </Radio>
                      </Radio.Group>
                    </div>
                  ) : (
                    <Input
                      value={i.option_value}
                      name="option_value"
                      onChange={(e) => handleChange(index, e)}
                      type="text"
                      placeholder="Option"
                      className="text-sm  mt-1 w-full border-gray-200 border-2 focus:border-primary  h-9 px-2 "
                    />
                  )}
                  <Button
                    disabled={from === "setanswer"}
                    onClick={() => handleRemove(index)}
                    className="rounded-full bg-red-200 text-base hover:bg-red-400 border-none flex justify-center items-center "
                  >
                    <MdCancel className="text-white   cursor-pointer" />
                  </Button>
                </div>
              );
            })}
          </div>
          <div className="flex mt-10 w-full justify-end items-center gap-4 cursor-pointer">
            <p
              onClick={() => setIsOpen(false)}
              className="bg-blue-600 hover:bg-blue-800 text-white text-xs p-2 rounded-md"
            >
              Cancel
            </p>
            <Button
              disabled={from === "setanswer" && Fill.correct_answer !== ""}
              onClick={() => {
                checkDisabled();
              }}
              style={{ color: "white" }}
              className="bg-primary border-none hover:bg-primaryhover text-white text-xs p-2 rounded-md"
            >
              {from === "add" ? "Add" : from === "edit" ? "Edit" : "Save"}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default QuestionModal;
