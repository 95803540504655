import Pool from "../Views/Main Panel/Pool";
import PrivateAuth from "./PrivateAuth";
import Users from "../Views/Main Panel/Users";
import Questions from "../Views/Main Panel/Questions/Questions";
import QuizWinners from "../Views/Main Panel/QuizWinner";
import Rewards from "../Views/Main Panel/Rewards";

const privateAdminRoute = () => {
  return [
    {
      element: <PrivateAuth />,
      children: [
        {
          path: "/quiz",
          element: <Pool />,
        },
        {
          path: "/users",
          element: <Users />,
        },
        {
          path: "/questions",
          element: <Questions />,
        },
        {
          path: "/quiz/winners",
          element: <QuizWinners />,
        },
        {
          path: "/quiz/rewards",
          element: <Rewards />,
        },
      ],
    },
  ];
};

export default privateAdminRoute;
