import React, { useEffect, useState } from "react";
import { getRewards, deleteReward } from "../../../services/apiFunctions";
import { Table, Image, Popconfirm, Pagination, Modal, Button } from "antd";
function TableData() {
  const [rewardsImages, setRewardsImages] = useState();
  const [img, setImg] = useState();
  const [imgPreview, setImgPreview] = useState(null);
  const [totalPage, setTotalPages] = useState(0);
  const [currentPage, setcurrentPage] = useState(1);
  const [pagesize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [visible, setVisible] = useState(false);
  const columns = [
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
    },

    {
      title: "Preview",
      dataIndex: "preview",
      key: "preview",
      render: (data, record) => (
        <p
          className="bg-primary text-white rounded-md justify-center text-center w-1/2 cursor-pointer"
          onClick={(e) => {
            setImgPreview(record.image);
            setVisible(true);
          }}
        >
          Preview
        </p>
      ),
    },
    {
      title: "Action",
      dataIndex: "delete",
      key: "delete",
      render: (data, record) => (
        <Popconfirm
          title="Delete the Image"
          description="Are you sure to delete this Image?"
          onConfirm={(e) => confirm(data, record, e)}
          onCancel={cancel}
          okText="Yes"
          cancelText="No"
        >
          <p className="bg-primary text-white rounded-md justify-center text-center w-1/2 cursor-pointer">
            Delete
          </p>
        </Popconfirm>
      ),
    },
  ];
  const cancel = (e) => {
    console.log(e);
  };
  const confirm = (data, record) => {
    deleteReward(record._id)
      .then((res) => {
        getRewardsTable(currentPage, pagesize);
      })
      .catch((err) => console.log(err));
  };

  function getRewardsTable(page, pageSize) {
    getRewards(`?page=${page}&limit=${pageSize}`)
      .then((response) => {
        console.log(response);

        setRewardsImages(response.data.data);
        setTotalRecords(response.data.totalRecords);
        setTotalPages(response.data.totalPage);
        setImg(
          rewardsImages.map((image) => {
            return image.image;
          })
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    getRewardsTable(currentPage, pagesize);
  }, [currentPage, pagesize]);
  const handlePageChange = (page, pageSize) => {
    setcurrentPage(page);
    // getRewardsTable(currentPage, pagesize);
  };
  function handleOk() {
    setVisible(false);
  }

  return (
    <div>
      <Table
        columns={columns}
        dataSource={rewardsImages}
        pagination={false}
        className="w-screen sm:w-screen "
      />
      <Pagination
        className="mt-2 bg-white w-full "
        onChange={handlePageChange}
        current={currentPage}
        defaultCurrent={1}
        total={10 * totalPage}
        showSizeChanger={false}
      />
      {visible && (
        <Image
          width={100}
          alt="reward"
          crossOrigin="anonymous"
          style={{
            display: imgPreview ? "block" : "none",
          }}
          preview={{
            visible,
            src: "https://api.joc.today/uploads/rewards/" + imgPreview,
            onVisibleChange: (value) => {
              setVisible(value);
            },
          }}
        />
      )}
    </div>
  );
}
export default TableData;
