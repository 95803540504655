import { DatePicker, Input, Modal, Space, TimePicker, message } from "antd";
import React, { useEffect, useState } from "react";
import { addNewPool, editNewPool } from "../../../services/apiFunctions";
import locale from "antd/es/date-picker/locale/en_US";
import { messageConfiguration } from "../../../utils";
import dayjs from "dayjs";
import "dayjs/locale/en"; // Import the locale you want to use
require("dayjs/plugin/timezone"); // Import the timezone plugin
const utc = require("dayjs/plugin/utc"); // Import the UTC plugin
dayjs.extend(utc); // Extend dayjs with UTC plugin
dayjs.locale("en"); // Set the locale for dayjs
dayjs.extend(require("dayjs/plugin/timezone"));
dayjs.tz.setDefault("Asia/kolkata");
const AddPool = ({ isOpen, setIsOpen, Fill, from }) => {
  const [subject, setSubject] = useState("");
  const [start_time, setStartTime] = useState("");
  const [end_time, setEndTime] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    if (Fill && from === "edit") {
      setStartDate(dayjs(Fill.pool_start_time));
      setStartTime(dayjs(Fill.pool_start_time));
      setEndDate(dayjs(Fill.pool_end_time));
      setSubject(Fill.subject);
      setEndTime(dayjs(Fill.pool_end_time));
    }
  }, [Fill, from]);

  const disabledDate = (current) => {
    return current && current.isBefore(dayjs().startOf("day"), "day");
  };
  const createPool = (param) => {
    if (from === "edit") {
      param["pool_id"] = Fill._id;
    }
    if (from === "add") {
      addNewPool(param)
        .then((response) => {
          if (response.data.status) {
            message.success({ content: "Record Created Successfully" });
            setIsOpen(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      editNewPool(param)
        .then((response) => {
          if (response.data.status) {
            message.success({ content: "Record Modified Successfully" });
            setIsOpen(false);
          } else {
            message.error({ content: "Record Already Exists" });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const checkDisabled = () => {
    let payload = {
      subject: subject,
    };
    const date = startDate["$d"].toString().split(" ").slice(0, 4).join(" ");
    const time = start_time["$d"].toString().split(" ").slice(4).join(" ");
    const enddate = endDate["$d"].toString().split(" ").slice(0, 4).join(" ");
    const endtime = end_time["$d"].toString().split(" ").slice(4).join(" ");
    const combinedstart = date + " " + time;
    const combinedend = enddate + " " + endtime;
    payload.pool_start_time = new Date(combinedstart).getTime();
    payload.pool_end_time = new Date(combinedend).getTime();
    if (!subject) {
      message.open(messageConfiguration("error", "Fill the Subject field!", 3));
    } else if (!startDate) {
      message.open(messageConfiguration("error", "Set Start Date", 3));
    } else if (!start_time) {
      message.open(messageConfiguration("error", "Set Start Time", 3));
    } else if (!endDate) {
      message.open(messageConfiguration("error", "Set End Date", 3));
    } else if (!end_time) {
      message.open(messageConfiguration("error", "Set End Time", 3));
    } else if (!(endDate >= startDate)) {
      message.open(
        messageConfiguration(
          "error",
          "End Date must be Greater than Start Date",
          3
        )
      );
    } else if (Date.now() >  new Date(combinedend).getTime()) {
      message.open(
        messageConfiguration(
          "error",
          "End Time must be Greater than Current Time",
          3
        )
      );
    } else if (Date.now() >  new Date(combinedstart).getTime()) {
      message.open(
        messageConfiguration(
          "error",
          "Start Time must be Greater than Current Time",
          3
        )
      );
    } else {
      createPool(payload);
    }
  };

  const handleOk = () => {
    setIsOpen(false);
  };
  const handleCancel = () => {
    setIsOpen(false);
  };
  return (
    <>
      <Modal
        footer={null}
        open={isOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        title={`${from === "add" ? "Add" : "Edit"} Pool Title`}
      >
        <div className="h-[200px] ">
          <div>
            <Input
              type="text"
              value={subject}
              className="h-9 rounded-md text-xs border-2 border-gray-200 mb-5"
              placeholder="Enter Pool Title"
              onChange={(e) => {
                setSubject(e.target.value);
              }}
            />
            <Space>
              <p className="text-xs text-slate-500"> Start Date</p>
              <DatePicker
                className="border-2 border-gray-200"
                locale={locale}
                value={startDate}
                disabledDate={disabledDate}
                onChange={(date, dateString) => {
                  setStartDate(date); // Save the Moment object directly
                }}
              />
              <p className="text-xs text-slate-500">Start Time</p>
              <TimePicker
                className="custom-timepicker border-2 border-gray-200"
                placeholder="HH:MM:SS"
                style={{ width: "200px" }}
                locale={locale}
                value={start_time}
                onChange={(time) => {
                  setStartTime(time);
                }}
              />
            </Space>
            <Space className="mt-3">
              <p className="text-xs text-slate-500">End Date </p>
              <DatePicker
                disabledDate={disabledDate}
                className="border-2 border-gray-200"
                value={endDate}
                locale={locale}
                onChange={(date, dateString) => {
                  setEndDate(date);
                }}
              />

              <p className="text-xs text-slate-500">End Time </p>
              <TimePicker
                locale={locale}
                value={end_time}
                className="border-2 border-gray-200"
                placeholder="HH:MM:SS"
                onChange={(time) => {
                  setEndTime(time);
                }}
                style={{ width: "200px" }}
              />
            </Space>
          </div>

          <div className="flex justify-end items-center gap-4 cursor-pointer mt-4">
            <p
              onClick={() => setIsOpen(false)}
              className="bg-blue-600 hover:bg-blue-800 text-white text-xs p-2 rounded-md"
            >
              Cancel
            </p>
            <p
              className="bg-primary hover:bg-primaryhover text-white text-xs p-2 rounded-md "
              onClick={() => {
                checkDisabled();
              }}
            >
              {from === "add" ? "Add" : "Edit"}
            </p>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddPool;
