
export const endpoint = {

loginApi:`/admin/login`,
logout:`/admin/logout`,
PoolList:`/pool/pool`,
addNewPool:`/pool/pool`,
addQuestion:"/pool/questions",
getPoolQuetsions:`/pool/questions`,
deleteRow:`/pool/questions`,
declareResult:`pool/result`,
winnerList:`/admin/result_list`,
poolRank:`/pool/rank`,
deleted_Pool:`pool/pool`,
getuserlist:"admin/user-list",
getSearchUser:`admin/search-user`,
modifyUser:"admin/modify-user",
deleteUser:"admin/delete-user/",
setanswer:"pool/set-answer-bulk",
setrank:"pool/rank",
getSelectReward:`admin/upload-reward`,
getRewards:`admin/reward-list`,
deleteReward:`admin/delete-reward?id=`,
  }