import React, { useState } from "react";
import { Button, message } from "antd";
import { getSelectReward } from "../../../services/apiFunctions";
import TableData from "./TableData";
import { messageConfiguration } from "../../../utils";
const Rewards = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles(files);
  };
  console.log("handleFileChange", selectedFiles.length);
  const handleUpload = async () => {
    const formData = new FormData();
    selectedFiles.forEach((file, index) => {
      formData.append(`file${index}`, file);
    });

    try {
      getSelectReward(formData).then((response) => {
        if (response.data.status === true) {
          message.open(
            messageConfiguration("success", response.data.message, 2)
          );
          window.location.reload();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="bg-primary mt-2 text-white font-bold p-2 text-lg">
        Rewards
      </div>
      <>
        <div className=" p-4 m-4 rounded-md grid grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-3">
          <div className="bg-white p-4 m-4 rounded-sm h-24  ">
            <input
              type="file"
              multiple
              accept="image/*"
              onChange={handleFileChange}
              listType="picture"
              list={[...selectedFiles]}
            />
            <Button
              disabled={selectedFiles.length === 0 ? true : false}
              onClick={handleUpload}
              style={{ color: "black" }}
              className="bg-primary cursor-pointer border-none  text-sm mt-2 font-semibold  h-8 px-4 text-black w-full  rounded-md "
            >
              Upload
            </Button>
          </div>

          <div className="mt-4 overflow-y-scroll h-auto bg-white col-span-2 rounded-sm">
            <TableData />
          </div>
        </div>
      </>
    </div>
  );
};
export default Rewards;
