import {
  Pagination,
  Popconfirm,
  Radio,
  Spin,
  Switch,
  Table,
  Input,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  GetUserList,
  deleteUser,
  modifyUser,
  getSearchUser,
} from "../../../services/apiFunctions";

const Users = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [pagesize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [allUsers, seAllUsers] = useState([]);
  const [type, setType] = useState("all");
  const { Search } = Input;
  const getUserList = (page, pageSize, type = "all") => {
    GetUserList(`?page=${page}&limit=${pageSize}&type=${type}`)
      .then((res) => {
        seAllUsers(res.data.data);
        setTotalRecords(res.data.totalRecords);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };


  useEffect(() => {
    getUserList(currentPage, pagesize, type);
  }, [pagesize, type]);
  const handlePageChange = (page, pageSize) => {
    setcurrentPage(page);
  };
  useEffect(() => {
    if (searchTerm === "") {
      getUserList(currentPage, pagesize, type);
    } else {
      getSearchUser(`?search=${searchTerm}&limit=${pagesize}&page=${currentPage}`)
        .then((response) => {
          if (response.status) {
            seAllUsers(response.data.data);
            setTotalRecords(response.data.totalRecords);
            if (response.data.searchString === "") {
              getUserList(currentPage, pagesize, type);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [searchTerm,pagesize,type,currentPage]);
  const handleModify = (data, record) => {
    const payload = {
      user_id: record._id,
      active: !record.is_active,
    };
    modifyUser(payload)
      .then((res) => {
        getUserList(currentPage, pagesize, type);
      })
      .catch((err) => console.log(err));
  };
  const columns = () => {
    return [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Mobile Number",
        dataIndex: "mobile",
        key: "mobile",
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "Status",
        dataIndex: "is_active",
        key: "is_active",
        render: (data, record) => (
          <Switch
            className="toggle"
            checked={record.is_active}
            checkedChildren="Active"
            unCheckedChildren="InActive"
            onChange={(e) => {
              handleModify(data, record, e);
            }}
          />
        ),
      },
      {
        title: "Action",
        dataIndex: "delete",
        key: "delete",
        render: (data, record) => (
          <Popconfirm
            title="Delete the User"
            description="Are you sure to delete this user?"
            onConfirm={(e) => confirm(data, record, e)}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <p className="bg-primary text-white rounded-md justify-center text-center w-1/2 cursor-pointer">
              Delete
            </p>
          </Popconfirm>
        ),
      },
    ];
  };

  const confirm = (data, record) => {
    deleteUser(record._id)
      .then((res) => {
        getUserList(currentPage, pagesize, type);
      })
      .catch((err) => console.log(err));
  };
  const cancel = (e) => {
    console.log(e);
  };
  const handleChangeType = (e) => {
    setType(e.target.value);
    getUserList(currentPage, e.target.value);
  };
  const onShowSizeChange = (page, pageSize) => {
    setcurrentPage(page);
    setPageSize(pageSize);
  };
  return (
    <>
      {isLoading ? (
        <div className="text-center mt-52">
          <Spin size="md" />
        </div>
      ) : (
        <div>
          {/* <p className="font-semibold text-xl text-white">All Users</p> */}
          <div className="flex justify-between place-items-end">
          <div className="radio-group  rounded-sm ">
              <Radio.Group onChange={(e) => handleChangeType(e)} value={type} className="bg-yellow-400  rounded-md ">
                <Radio value={"all"} className="text-white mt-1">All</Radio>
                <Radio value={"active"} className="text-white mt-1">Active</Radio>
                <Radio value={"inactive"} className="text-white mt-1">In Active</Radio>
              </Radio.Group>
            </div>
            <Search
              placeholder="search user here..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)
              }
              className="w-1/4 bg-yellow-400"
            />
         
          </div>
          {/* <div className="flex justify-end items-center"></div> */}
          <div className="bg-primary mt-2 text-white font-bold p-2 text-lg">
            All Users
          </div>
          <div className="mt-4 overflow-y-scroll h-96 bg-white ">
            <Table
              columns={columns()}
              dataSource={allUsers}
              pagination={false}
              className="w-screen sm:w-screen"
            />
          </div>
        </div>
      )}
      {allUsers.length !== 0 && (
        <Pagination
          showSizeChanger
          onShowSizeChange={onShowSizeChange}
          className="mt-4 bg-white w-full rounded-md"
          onChange={handlePageChange}
          current={currentPage}
          defaultCurrent={1}
          total={totalRecords}
        />
      )}
    </>
  );
};

export default Users;
