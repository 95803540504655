import { Button, Spin, Modal, Table } from "antd";
import React, { useEffect, useState } from "react";
import { poolRank } from "../../../services/apiFunctions";

const WinnerModal = ({ isOpen, setIsOpen, Fill }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const colum = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Points 💰",
      dataIndex: "point",
    },
    {
      title: "Rank 🏅",
      dataIndex: "rank",
    },
  ];

  function getWinnerList() {
    const p_id = Fill._id;
    poolRank(`?role=admin&pool_id=${p_id}`)
      .then((response) => {
        setData(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }

  useEffect(() => {
    getWinnerList();
  }, []);
  function handleOk() {
    setIsOpen(false);
  }
  function handleCancel() {
    setIsOpen(false);
  }
  return (
    <>
      {isLoading ? (
        <div className="text-center mt-52">
          <Spin size="md" />
        </div>
      ) : (
        <>
          <Modal
            footer={null}
            open={isOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            title={"Winner Details 🏆 "}
            
          >
            <div >
              <div className="w-full  p-4 mt-4 h-[250px] overflow-y-scroll ">
                <Table dataSource={data} columns={colum} pagination={false} />
              </div>
              <div className="flex mt-10 w-full justify-end items-center gap-4 cursor-pointer">
                <Button
                  style={{ color: "white" }}
                  className="bg-primary border-none hover:bg-orange-300 text-white text-xs p-2 rounded-md"
                  onClick={handleOk}
                >
                  Ok
                </Button>
              </div>
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

export default WinnerModal;
