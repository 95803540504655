import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import BasicLayout from '../AdminLayout';

function PrivateAuth(props) {
  const isAuthenticated = () => {
    const user = JSON.parse(localStorage.getItem('login'));
    return user;
};

  return isAuthenticated() ? (
    <>
     <BasicLayout>
        <Outlet/>
     </BasicLayout>
    </>
  ) : (
    <Navigate to="/" replace />
  )
}
export default PrivateAuth
