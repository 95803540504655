import { Delete, Get, Patch, Post, getAPIUrl,PostFormData } from './apiMethod'
import {  endpoint } from './global'
export const loginApi = (data,param = '') => {
  const url = getAPIUrl(endpoint.loginApi, param)
  return Post(url,data,false)
}

export const logout = (param = '') => {
  const url = getAPIUrl(endpoint.logout, param)
  return Get(url,false)
}
export const PoolList = (param = '') => {
  const url = getAPIUrl(endpoint.PoolList,param)
  return Get(url)
}
export const winnerList = (param = '') => {
  const url = getAPIUrl(endpoint.winnerList,param)
  return Get(url)
}
export const poolRank = (param = '') => {
  const url = getAPIUrl(endpoint.poolRank,param)
  return Get(url)
}
export const getPoolQuetsions = (param = '') => {
  const url = getAPIUrl(endpoint.getPoolQuetsions,param)
  return Get(url)
}
export const addNewPool = (data,param = '') => {
  const url = getAPIUrl(endpoint.addNewPool, param)
  return Post(url,data)
}
export const editNewPool = (data,param = '') => {
  const url = getAPIUrl(endpoint.addNewPool, param)
  return Patch(url,data)
}
export const declareResult = (param = '') => {
  const url = getAPIUrl(endpoint.declareResult,param)
  return Get(url)
}
export const addQuestion = (data,param = '') => {
  const url = getAPIUrl(endpoint.addQuestion, param)
  return Post(url,data)
}
export const editQuestion = (data,param = '') => {
  const url = getAPIUrl(endpoint.addQuestion, param)
  return Patch(url,data)
}
export const deleteRow = (param = '') => {
  const url = getAPIUrl(endpoint.deleteRow, param)
  return Delete(url)
}
export const deleted_Pool = (param = '') => {
  const url = getAPIUrl(endpoint.deleted_Pool, param)
  return Delete(url)
}
export const GetUserList = (param = '') => {
  const url = getAPIUrl(endpoint.getuserlist,param)
  return Get(url)
}
export const getSearchUser = (param = '') => {
  const url = getAPIUrl(endpoint.getSearchUser,param)
  return Get(url)
}
export const modifyUser = (data,param = '') => {
  const url = getAPIUrl(endpoint.modifyUser,param)
  return Patch(url,data)
}
export const deleteUser = (param = '') => {
  const url = getAPIUrl(endpoint.deleteUser,param)
  return Delete(url)
}
export const setBulkAnswer = (data,param = '') => {
  const url = getAPIUrl(endpoint.setanswer,param)
  return Patch(url,data)
}
export const setRank = (data,param = '') => {
  const url = getAPIUrl(endpoint.setrank,param)
  return Post(url,data)
}

export const getSelectReward=(data,param="")=>{
  const url = getAPIUrl(endpoint.getSelectReward,param);
  return PostFormData(url,data,true);
}
export const getRewards = (param = '') => {
  const url = getAPIUrl(endpoint.getRewards,param)
  return Get(url)
}
export const deleteReward = (param = '') => {
  const url = getAPIUrl(endpoint.deleteReward,param)
  return Delete(url)
}