import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Button, Radio, Switch, Tooltip, message } from "antd";
import { ExtractDate, ExtractTime } from "../../../utils";
import AddPool from "./AddPool";
import { Pagination, Spin } from "antd";
import {
  PoolList,
  deleted_Pool,
  editNewPool,
} from "../../../services/apiFunctions";
import { MdDeleteOutline, MdModeEditOutline } from "react-icons/md";
import ResultModel from "./ResultModel";
const Pool = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openResultModel, setOpenResultModal] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setcurrentPage] = useState(1);
  const [totalPage, setTotalPages] = useState(0);
  const [filldata, setFilldata] = useState([]);
  const [type, setType] = useState("all");
  const [from, setFrom] = useState("add");
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  function getPoolList(currentPage, type = "all") {
    PoolList(`?page=${currentPage}&limit=10&role=admin&type=${type}`)
      .then((response) => {
        //  setData(response.data.data);
        setData(
          response.data.data.sort((a, b) => {
            if (a.createdAt < b.createdAt) {
              return 1;
            }
            return -1;
          })
        );
        setcurrentPage(response.data.currentPage);
        setTotalPages(response.data.totalPage);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }

  function deletePool(pool_id) {
    deleted_Pool(`?pool_id=${pool_id}&deleted=${true}`)
      .then((response) => {
        if (response?.status) {
          message.success(response.data.message);
          getPoolList(currentPage);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(() => {
    if (!openModal) {
      getPoolList(currentPage, type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, openModal]);
  const handlePageChange = (page, pageSize) => {
    setcurrentPage(page);
  };
  const handleChangeType = (e) => {
    setType(e.target.value);
    getPoolList(currentPage, e.target.value);
  };
  const handleChangeActive = (e, val) => {
    setIsActive(!val.is_active);
    const payload = {
      pool_id: val._id,
      active: !val.is_active,
      subject: val.subject,
      pool_end_time: val.pool_end_time,
      pool_start_time: val.pool_start_time,
    };
    editNewPool(payload)
      .then((res) => getPoolList(currentPage, type))
      .catch((err) => console.log(err));
  };
  return (
    <>
      {isLoading ? (
        <div className="text-center mt-52">
          <Spin size="md" />
        </div>
      ) : (
        <>
          <div className="flex items-center justify-between">
            <div className="flex justify-end items-center">
              <div className="radio-group  rounded-sm">
                <Radio.Group onChange={(e) => handleChangeType(e)} value={type} className="bg-yellow-400  rounded-md ">
                  <Radio value={"all"} className="text-white mt-1">All</Radio>
                  <Radio value={"active"} className="text-white mt-1">Live</Radio>
                  <Radio value={"inactive"} className="text-white mt-1">In Active</Radio>
                </Radio.Group>
              </div>
            </div>
            <p
              onClick={() => {
                setOpenModal(true);
                setFrom("add");
              }}
              className="bg-primary cursor-pointer text-white text-xs p-4 rounded-md hover:bg-orange-300"
            >
              Add Pool
            </p>
          </div>
          <div className="bg-primary mt-2 text-white font-bold p-2 text-lg">
            All Quizes
          </div>

          {data.length !== 0 ? (
            data.map((i, index) => {
              const trim =
                i.subject.length > 10
                  ? i.subject.substring(0, 10) + "..."
                  : i.subject;
              return i.result_declared ? (
                ""
              ) : (
                <>
                  <div
                    key={index}
                    className="bg-white shadow-md shadow-gray-400 p-4 m-4 rounded-md"
                  >
                    <div className="grid grid-cols-1 gap-4 md:grid-cols-7">
                      <div>
                        <div
                          className="flex cursor-pointer justify-start capitalize items-center"
                          onClick={() => {
                            navigate("/questions", {
                              state: {
                                pool_id: i._id,
                                alldata: i,
                              },
                            });
                          }}
                        >
                          <p className="font-semibold">{index + 1}</p>.{" "}
                          <Tooltip color="gray" title={i.subject}>
                            <p>{trim}</p>
                          </Tooltip>
                        </div>
                        <div className="flex justify-start items-center gap-1">
                          <div
                            className={` ${
                              Date.now() < i.pool_end_time &&
                              Date.now() > i.pool_start_time
                                ? "bg-green-600"
                                : "bg-red-700"
                            } w-2 h-2 rounded-full`}
                          ></div>
                          <p className="text-[11px]">
                            {Date.now() > i.pool_start_time &&
                            Date.now() < i.pool_end_time
                              ? "Live"
                              : "Not Available"}
                          </p>
                        </div>
                      </div>
                      <p>
                        <span className="font-semibold">Start Time</span>:{" "}
                        {ExtractDate(i.pool_start_time)}{" "}
                        {ExtractTime(i.pool_start_time)}
                      </p>
                      <p>
                        <span className="font-semibold">End Time</span>:{" "}
                        {ExtractDate(i.pool_end_time)}{" "}
                        {ExtractTime(i.pool_end_time)}
                      </p>
                      <Switch
                        disabled={
                          Date.now() > i.pool_end_time ||
                          (Date.now() > i.pool_start_time &&
                            Date.now() < i.pool_end_time)
                        }
                        className="toggle"
                        checkedChildren="Active"
                        unCheckedChildren="InActive"
                        checked={i.is_active}
                        onChange={(e) => {
                          handleChangeActive(e, i);
                        }}
                      />
                      {/* {Date.now() > i.pool_end_time && ( */}
                      <Button
                        disabled={!(Date.now() > i.pool_end_time)}
                        style={{ color: "black" }}
                        className="bg-primary cursor-pointer flex justify-center border-none items-center text-xs font-semibold  h-8 px-4 text-black   rounded-md "
                        onClick={() => {
                          setFilldata(i);
                          setOpenResultModal(true);
                        }}
                      >
                        Result
                      </Button>
                      {/* )} */}
                      {/* {Date.now() < i.pool_start_time && ( */}
                      <Button
                        style={{ color: "black" }}
                        disabled={!(Date.now() < i.pool_start_time)}
                        className="bg-primary cursor-pointer flex justify-center border-none items-center text-sm font-semibold  h-8 px-4 text-black   rounded-md"
                        onClick={() => {
                          setFrom("edit");
                          setFilldata(i);
                          setOpenModal(true);
                        }}
                      >
                        <MdModeEditOutline />
                      </Button>
                      {/* )} */}
                      {/* {Date.now() < i.pool_start_time && ( */}
                      <Button
                        style={{ color: "black" }}
                        disabled={!(Date.now() < i.pool_start_time)}
                        className="bg-primary cursor-pointer flex justify-center border-none items-center text-sm font-semibold  h-8 px-4 text-black   rounded-md "
                        onClick={() => deletePool(i._id)}
                      >
                        <MdDeleteOutline />
                      </Button>
                      {/* )} */}
                    </div>
                  </div>
                </>
              );
            })
          ) : (
            <p className="flex justify-center items-center h-full font-semibold">
              No quizes
            </p>
          )}
          {openModal && (
            <AddPool
              isOpen={openModal}
              setIsOpen={setOpenModal}
              Fill={filldata}
              setFill={setFilldata}
              from={from}
            />
          )}
          {openResultModel && (
            <ResultModel
              OpenResult={openResultModel}
              setOpenResult={setOpenResultModal}
              Fill={filldata}
              setFill={setFilldata}
              from={from}
            />
          )}
          {data.length !== 0 && (
            <Pagination
              onChange={handlePageChange}
              current={currentPage}
              defaultCurrent={1}
              total={10 * totalPage}
              className="bg-white w-full rounded-md"
            />
          )}
        </>
      )}
    </>
  );
};

export default Pool;
