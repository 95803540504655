import React, { useEffect, useState } from "react";
import { Button, Pagination, Spin, message } from "antd";
import { useLocation } from "react-router";
import QuestionModal from "./QuestionModal";
import { MdDeleteOutline, MdModeEditOutline } from "react-icons/md";
import {
  getPoolQuetsions,
  deleteRow,
  setBulkAnswer,
} from "../../../services/apiFunctions";
import { messageConfiguration } from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import { clearAnswer } from "../../../Redux/ReduxSlice";
const Questions = () => {
  const location = useLocation();
  const { pool_id, alldata } = location.state;
  const [isLoading, setIsLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [currentPage, setcurrentPage] = useState(1);
  const[correct_answer,setCorrectAnswer]=useState([])
  const [totalPage, setTotalPages] = useState();
  const [data, setData] = useState([]);
  const [totalRecord, setTotalRecords] = useState(null);
  const [filldata, setFilldata] = useState([]);
  const [from, setFrom] = useState("add");
  const getAnswerArrayObj = useSelector((state) => state.answer.Answers);
  const dispatch = useDispatch();
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  useEffect(() => {
    return () => {
      dispatch(clearAnswer());
    };
  }, []);

  function getQuestion() {
    getPoolQuetsions(
      `?pool_id=${pool_id}&page=${currentPage}&limit=10&role=admin`
    )
      .then((response) => {
        setData(response.data.question);
       setCorrectAnswer(data.map((i)=>{
        return i.correct_answer
       }));
        setcurrentPage(response.data.currentPage);
        setTotalPages(response.data.totalPage);
        setTotalRecords(response.data.totalRecords);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }
  
  function deleteQuestion(q_id) {
    deleteRow(`?question_id=${q_id}&deleted=${true}`)
      .then((response) => {
        if (response?.status) {
          message.success(response.data.message);
          getQuestion();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  useEffect(() => {
    if (!openModal) {
      getQuestion(currentPage);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, openModal]);
  const handlePageChange = (page, pageSize) => {
    setcurrentPage(page);
  };

  const handleSaveAnswers = () => {
    if (data.filter((i) => i.correct_answer !== "").length !== 0) {
      message.open(messageConfiguration("warning", "Already saved answers", 3));
    } else if (data.length === 0) {
      message.open(
        messageConfiguration(
          "warning",
          "Can't save without having questions",
          3
        )
      );
    } else if (Object.keys(getAnswerArrayObj).length !== totalRecord) {
      message.open(
        messageConfiguration(
          "warning",
          "Can't save without selecting answers",
          3
        )
      );
    } else {
      let payload = { answer_list: {} };
      const list = Object.keys(getAnswerArrayObj).map((key) => {
        return {
          question_id: key,
          answer: getAnswerArrayObj[key],
        };
      });
      payload.answer_list = list;
      setBulkAnswer(payload)
        .then((res) => {
          message.open(
            messageConfiguration("success", "Saved Answer Successfully", 3)
          );
          getQuestion(currentPage);
        })
        .catch((err) => console.log(err));
    }
  };
  return (
    <>
      {isLoading ? (
        <div className="text-center mt-52">
          <Spin size="md" />
        </div>
      ) : (
        <>
          <div className="flex items-center gap-4 justify-end">
            <Button
              style={{ color: "white" }}
              onClick={() => {
                Date.now() > alldata.pool_start_time &&
                Date.now() < alldata.pool_end_time
                  ? message.open(
                      messageConfiguration(
                        "error",
                        "Can't add question in running quiz",
                        3
                      )
                    )
                  : setOpenModal(true);
                setFrom("add");
              }}
              className="bg-primary border-none flex justify-center items-center cursor-pointer text-white text-xs p-4 rounded-md hover:bg-primaryhover"
            >
              Add Question
            </Button>
            <Button
              // disabled={
              //   Object.keys(getAnswerArrayObj).length !== totalRecord ||
              //   data.length === 0
              //   //  ||
              //   // data.map((i) => i.correct_answer !== "").length !== 0
              // }
              onClick={() => handleSaveAnswers()}
              style={{ color: "white" }}
              className="bg-primary cursor-pointer border-none flex justify-center items-center text-white text-xs p-4 rounded-md hover:bg-primaryhover"
            >
              Save And View
            </Button>
          </div>
          {data.length === 0 ? (
            <div className="flex justify-center items-center font-bold text-lg text-slate-400 h-full ">
              No Questions
            </div>
          ) : (
            data.map((i, index) => {
              const trim =
                i.question.length > 100
                  ? i.question.substring(0, 10) + "..."
                  : i.question;
              return (
                <div
                  key={index}
                  className="bg-white relative shadow-md shadow-gray-500 p-4 m-4 rounded-md"
                >
                  <div className="flex flex-wrap justify-start gap-2 mt-4 md:gap-4 items-center">
                    <p className="capitalize font-semibold  ">
                      Question {index + 1}:
                    </p>
                    <p> {i.question}</p>
                  </div>
                  <div className="absolute top-2 right-2">
                    <p> 💰: {i.points}</p>
                  </div>
                  <div className="leading-8 font-normal capitalize">
                    {i.options.map((item, index) => {
                      const alphabetLabel = String.fromCharCode(65 + index);
                      return (
                        <p key={index}>
                          {alphabetLabel}) {item.option_value.toUpperCase()}
                        </p>
                      );
                    })}
                  </div>
                  <div className="flex flex-wrap flex-row justify-end items-center gap-4 md:gap-4 mt-4">
                    <Button
                      style={{ color: "black" }}
                      disabled={
                        Date.now() > alldata.pool_start_time &&
                        Date.now() < alldata.pool_end_time
                      }
                      className="bg-primary border-none  cursor-pointer flex justify-center items-center rounded-md text-black h-8 "
                      onClick={() => {
                        setFilldata(i);
                        setOpenModal(true);
                        setFrom("edit");
                      }}
                    >
                      <MdModeEditOutline />
                    </Button>
                    <Button
                      style={{ color: "black"  }}
                      disabled={i.correct_answer !== ""}
                      className="bg-primary cursor-pointer flex justify-center border-none items-center rounded-md text-black h-8 "
                      onClick={() => {
                        setFilldata(i);
                        setOpenModal(true);
                        setFrom("setanswer");
                      }}
                    >
                      {
                     i.correct_answer===""?"Set Answer":`Answer is ${String.fromCharCode(64+(Number(i.correct_answer)))}`
                      }
                    </Button>
                    <Button
                      style={{ color: "black" }}
                      disabled={
                        Date.now() > alldata.pool_start_time &&
                        Date.now() < alldata.pool_end_time
                      }
                      className="bg-primary border-none  cursor-pointer flex justify-center items-center rounded-md text-black h-8 "
                      onClick={() => deleteQuestion(i._id)}
                    >
                      <MdDeleteOutline />
                    </Button>
                  </div>
                </div>
              );
            })
          )}
          {openModal && (
            <QuestionModal
              isOpen={openModal}
              selectedAnswers={selectedAnswers}
              setSelectedAnswers={setSelectedAnswers}
              setIsOpen={setOpenModal}
              from={from}
              setFrom={setFrom}
              Fill={filldata}
              setFill={setFilldata}
            />
          )}
          {data.length !== 0 && (
            <Pagination
              onChange={handlePageChange}
              current={currentPage}
              defaultCurrent={1}
              total={10 * totalPage}
            />
          )}
        </>
      )}
    </>
  );
};

export default Questions;
