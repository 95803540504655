import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  CrownOutlined,
  TrophyOutlined,
  GiftOutlined
} from "@ant-design/icons";
import { Layout, Menu, Button } from "antd";
import { images } from "../assets/images";
import { MdSupervisedUserCircle } from "react-icons/md";
const { Header, Sider, Content } = Layout;

const BasicLayout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      window.innerWidth <= 600 ? setCollapsed(true) : setCollapsed(false);
    };
    window.addEventListener("resize", handleResize);
    const stored = localStorage.getItem("selected");
    if (stored) {
      setSelectedMenu(stored);
    }
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    localStorage.setItem("selected", selectedMenu);
  }, [selectedMenu]);
  function getlogout() {
    // logout()
    //   .then((response) => {
    //     if (response.status) {
    //       localStorage.clear();
    //       navigate("/");
    //       message.success({ content: "Successfully logged out" });
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    localStorage.clear();
    navigate("/");
  }
  return (
    <Layout className="min-h-screen sidebar">
      <Sider
        style={{ position: "fixed", height: "100vh" }}
        collapsible
        collapsed={collapsed}
        onCollapse={() => setCollapsed(!collapsed)}
      >
        <div className="demo-logo-vertical d-flex justify-content-center m-2">
          {!collapsed ? (
            <img
              src={images.Logo}
              alt="logo"
              className="w-full mt-9  h-28 flex justify-center  items-center"
            />
          ) : (
            <img
              src={images.Logo}
              alt="logo"
              className="px-2 mt-9"
              style={{ display: "flex" }}
              width={"100px"}
            />
          )}
        </div>
        <Menu
          theme="dark"
          mode="inline"
          className="text-base mt-9"
          selectedKeys={[selectedMenu]}
          items={[
            {
              key: "1",
              icon: <CrownOutlined />,
              label: "Quiz",
              onClick: () => {
                setSelectedMenu("1");
                navigate("/quiz");
              },
            },
            {
              key: "2",
              icon: <MdSupervisedUserCircle />,
              label: "Users",
              onClick: () => {
                setSelectedMenu("2");
                navigate("/users");
              },
            },
            {
              key: "3",
              icon: <TrophyOutlined />,
              label: "Quiz Winner",
              onClick: () => {
                setSelectedMenu("3");
                navigate("/quiz/winners");
              },
            },
            {
              key: "4",
              icon: <GiftOutlined/>,
              label: "Rewards",
              onClick: () => {
                setSelectedMenu("4");
                navigate("/quiz/rewards");
              },
            },
          ]}
        />
      </Sider>
      <Layout>
        <Header style={{ marginLeft: collapsed ? 110 : 230, marginTop: 20 }}>
          <div className="flex bg-black justify-between px-2 items-center">
            {" "}
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined className="text-white"/> : <MenuFoldOutlined className="text-white"/>}
              onClick={() => setCollapsed(!collapsed)}
            
              style={{
                fontSize: "22px",
                width: 50,
                height: 50,
                alignSelf: "flex-start",
            
              }}
            />{" "}
            <Button
              className="bg-primary hover:bg-orange-300 border-none outline-none text-xs"
              style={{
                color: "white",
              }}
              onClick={getlogout}
            >
              Log Out
            </Button>
          </div>
        </Header>
        <Content
          className={`bg-black h-full p-3 md:p-5 ${
            collapsed ? "ml-[110px]" : "ml-[230px]"
          }`}
        >
          {/* <div className="mb-5 flex justify-start items-center gap-4">
            <ArrowLeftOutlined className="cursor-pointer"/>
            <p className="font-semibold text-xl">{subheading}</p>
          </div> */}
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default BasicLayout;
