import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { setLocalStorageItem } from "../../../../src/utils/index";
import { Button, message, Image } from "antd";
import { images } from "../../../assets/images";
import { loginApi } from "../../../services/apiFunctions";
function Login() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({ email: "", password: "" });
  const handlelogin = () => {
    setLoading(true);
    const data = {
      email: userData.email,
      password: userData.password,
    };
    loginApi(data)
      .then((response) => {
        if (response.data.status) {
          setLocalStorageItem("access_token", response.data.access_token);
          // setCookie("accessToken", response.data.access_token, { path: "/" });
          setLocalStorageItem("login", true);
          navigate("/quiz");
          message.success({ content: "SuccessFully Logged In" });
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      // When the "Enter" key is pressed, trigger the login action
      handlelogin();
    }
  };
  return (
    <div className="h-full w-full bg-black">
      {/* <div className=" flex justify-center items-center ">
        <p className="font-semibold text-6xl text-yellow-500 "></p>
      </div> */}
      <div className="  w-full flex items-center justify-center h-screen flex-col  rounded-sm  ">
        <div className="  p-7 w-full md:w-1/2 lg:w-1/4  rounded-md bg-primary h-auto  ">
          <div className="">
            {/* <Image
              width={100}
             className="rounded-full"
            //  src="mainLogo"
              src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
              preview={false}
            /> */}
             <img
              src={images.Logo}
              alt="logo"
              className="w-full mt-3 mb-3  h-48 flex justify-center  items-center"
            />
          </div>

          <p className=" text-center text-3xl   block text-gray-700  font-semibold mb-6">
            Admin Login
          </p>
          <div className=" my-4">
            <label
              className="block text-gray-700 text-md font-medium mb-4"
              htmlFor="username"
            >
              Email ID
            </label>
            <input
              type="email"
              placeholder="Enter Email"
              name="email"
              onKeyDown={handleKeyPress}
              required
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              onChange={(e) =>
                setUserData({ ...userData, email: e.target.value })
              }
            />
          </div>
          <div className="">
            <label
              className="block text-gray-700 text-md font-medium mb-4"
              htmlFor="password"
            >
              Password
            </label>
            <input
              type="password"
              placeholder="Enter Password"
              name="psw"
            onKeyDown={handleKeyPress}
              required
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-7"
              onChange={(e) =>
                setUserData({ ...userData, password: e.target.value })
              }
            />
          </div>
          {/* <div className=" text-white  text-center font-2xl py-3 px-4 rounded focus:outline-none focus:shadow-outline"> */}
          <Button
            loading={loading}
            type="button"
            className="text-white h-10 bg-black  w-full font-semibold"
            onClick={handlelogin}
          >
            Login
          </Button>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
}

export default Login;
